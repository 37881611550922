<template>
    <!-- <b-card header="JSON Web Token">
      <vue-json-pretty :deep="1" :data="token"></vue-json-pretty>
    </b-card> -->
     <b-card  no-body>
      <div slot="header">
        JSON Web Token
        <div class="card-header-actions">
          <b-link v-if="visual && !expanded" @click="expanded = true" class="card-header-action">
            <font-awesome-icon icon="expand-arrows-alt" />
          </b-link>
          <b-link v-if="visual && expanded" @click="expanded = false" class="card-header-action">
            <font-awesome-icon icon="compress-arrows-alt" />
          </b-link>
          <b-link v-if="!visual" @click="copyToken" class="card-header-action">
            <font-awesome-icon icon="clipboard" />
          </b-link>
          <b-link @click="visual = !visual" class="card-header-action">
            <font-awesome-icon icon="exchange-alt" />
          </b-link>
        </div>
      </div>
      <b-card-body>
        <vue-json-pretty v-show="visual" :deep="deep" :data="tokenParsed"></vue-json-pretty>
        <prism-editor v-show="!visual" :readonly="true" language="txt"  class="editor" v-model="token"></prism-editor>
      </b-card-body>

    </b-card>

</template>

<script>
import 'prismjs';
import 'prismjs/themes/prism.css';
import 'vue-prism-editor/dist/VuePrismEditor.css';
import PrismEditor from 'vue-prism-editor';
import VueJsonPretty from 'vue-json-pretty';
import copy from 'copy-text-to-clipboard';

export default {
  name: 'jwtexplorer',
  data() {
    return {
      expanded: false,
      visual: true
    };
  },
  components: {
    PrismEditor,
    VueJsonPretty
  },
  computed: {
    deep() {
      return this.expanded ? Number.POSITIVE_INFINITY : 1;
    },
    tokenParsed() {
      return this.$kauth.tokenParsed;
    },
    token() {
      return this.$kauth.token;
    }
  },
  methods: {
    copyToken() {
      copy(this.token);
    }
  }
};
</script>

<style scoped>

</style>
